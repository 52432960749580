const getters = {
    getUnidade: state => {
        return state.unidade;
    },
    getUnidades: state => {
        return state.unidades;
    },
    getEmpreendimentos: state => {
        const empreendimentosMap = new Map();
        state.unidades.forEach(unidade => {
            const { idempreendimento, nome } = unidade.empreendimento;
            if (!empreendimentosMap.has(idempreendimento)) {
                empreendimentosMap.set(idempreendimento, { idempreendimento, nome });
            }
        });
        return Array.from(empreendimentosMap.values());
    },
    getBlocos: state => {
        const blocosMap = new Map();
        state.unidades.forEach(unidade => {
            const { idbloco, nome } = unidade.bloco;
            const { idempreendimento } = unidade.empreendimento;
            if (!blocosMap.has(idbloco)) {
                blocosMap.set(idbloco, { idbloco, nome, idempreendimento });
            }
        });
        return Array.from(blocosMap.values());
    },
};
export default getters
