<template>
	<div class="pc-bloco">
		<div>
			<div class="pc-titleBloco">
				<div>
					<h3>Assistência Técnica</h3>
				</div>
			</div>
			<div class="boxAtendimento">
				<div class="boxAtendimento--header">
					<ul class="pc-list -full">
						<li>
							<p>
								Data de abertura
								<span>
									{{
											assistencia.data_cadastro
											| dateParse("YYYY-MM-DD HH:mm:ss")
											| dateFormat("DD/MM/YYYY HH:mm:ss")
									}}
								</span>
							</p>
						</li>
					</ul>
					<div class="pc-tagStatus">
						<p>
							Situação:
							<span :style="
								`background: ${assistencia.situacao.situacao_cor_bg}; color: ${assistencia.situacao.situacao_cor_nome};`
							">{{ assistencia.situacao.nome }}</span>
						</p>
					</div>
				</div>
				<ul class="pc-list -full">
					<li>
						<p>
							#ID
							<span>#{{ assistencia.idassistencia }}</span>
						</p>
					</li>
					<li>
						<p>
							Protocolo de atendimento:
							<span v-if="assistencia.protocolo_atendimento != null">{{ assistencia.protocolo_atendimento
							}}</span>
							<span v-else>--</span>
						</p>
					</li>
					<li v-if="
						assistencia.unidade != undefined ||
						assistencia.unidade_avulsa != undefined
					">
						<p>
							Empreendimento:
							<span v-if="assistencia.unidade != undefined">
								{{
										getSafe(
											() =>
												assistencia.unidade.empreendimento.nome
										)
								}}
							</span>
							<span v-else>
								{{
										getSafe(
											() =>
												assistencia.unidade_avulsa
													.empreendimento
										)
								}}
							</span>
						</p>
					</li>
					<li v-if="assistencia.unidade != undefined">
						<p>
							Etapa:
							<span>
								{{
										getSafe(() => assistencia.unidade.etapa.nome)
								}}
							</span>
						</p>
					</li>
					<li v-if="
						assistencia.unidade != undefined ||
						assistencia.unidade_avulsa != undefined
					">
						<p>
							Bloco:
							<span v-if="assistencia.unidade != undefined">
								{{
										getSafe(
											() => assistencia.unidade.bloco.nome
										)
								}}
							</span>
							<span v-else>
								{{
										getSafe(
											() => assistencia.unidade_avulsa.bloco
										)
								}}
							</span>
						</p>
					</li>
					<li v-if="
						assistencia.unidade != undefined ||
						assistencia.unidade_avulsa != undefined
					">
						<p>
							Unidade:
							<span v-if="assistencia.unidade != undefined">
								{{
										getSafe(() => assistencia.unidade.nome)
								}}
							</span>
							<span v-else>
								{{
										getSafe(
											() => assistencia.unidade_avulsa.unidade
										)
								}}
							</span>
						</p>
					</li>
					<li v-if="assistencia.area != undefined">
						<p>
							Empreendimento:
							<span>
								{{
										getSafe(
											() => assistencia.area.empreendimento
										)
								}}
							</span>
						</p>
					</li>
					<li v-if="assistencia.area != undefined">
						<p>
							Área:
							<span>{{ getSafe(() => assistencia.area.nome) }}</span>
						</p>
					</li>
					<li v-if="assistencia.area != undefined">
						<p>
							Descrição:
							<span>
								{{
										getSafe(() => assistencia.area.descricao)
								}}
							</span>
						</p>
					</li>

					<li v-if="assistencia.localidade != undefined">
						<p>
							Localidade:
							<span>
								{{
										getSafe(() => assistencia.localidade.nome)
								}}
							</span>
						</p>
					</li>
					<li v-if="assistencia.localidade != undefined">
						<p>
							Descrição:
							<span>
								{{
										getSafe(
											() => assistencia.localidade.descricao 
										)
								}}
							</span>
						</p>
					</li>
				</ul>
			</div>
			<div class="pc-titleBloco" v-if="assistencia.itens.length > 0">
				<h3>Itens de Manutenção</h3>
			</div>
			<div class="pc-tabela"  v-if="assistencia.itens.length > 0">
				<div class="pc-tabela--row pc-tabela--head">
					<div class="pc-tabela--td pc-f1">
						<p>#ID</p>
					</div>
					<div class="pc-tabela--td pc-f2">
						<p>Item</p>
					</div>
					<div class="pc-tabela--td pc-f2">
						<p>Descrição</p>
					</div>
					<div class="pc-tabela--td pc-f1">
						<p>Data de cadastro</p>
					</div>
					<div class="pc-tabela--td pc-f1">
						<p>Situação</p>
					</div>
					<div class="pc-tabela--td pc-f1" v-if="assistencia.permite_criar_visita">
						<p>Visita</p>
					</div>
				</div>
				<div class="pc-tabela--row" v-for="item in assistencia.itens" :key="item.iditem"
				v-if="item.visita_liberada && assistencia.itens.length > 0">
					<div class="pc-tabela--td pc-f1">{{ item.iditem }}</div>
					<div class="pc-tabela--td pc-f2">{{ getSafe(() => item.nome) }}</div>
					<div class="pc-tabela--td pc-f2">{{ getSafe(() => item.descricao) }}</div>
					<div class="pc-tabela--td pc-f1">
						<span>
							{{
									item.data_cadastro
									| dateParse("YYYY-MM-DD HH:mm:ss")
									| dateFormat("DD/MM/YYYY HH:mm:ss")
							}}
						</span>
					</div>
					<div class="pc-tabela--td pc-f1">
						<span class="pc-labelStatus" :style="
							`background: ${item.situacao.situacao_cor_bg}; color: ${item.situacao.situacao_cor_nome};`
						">{{ item.situacao.nome }}</span>
					</div>

					<div class="pc-tabela--td pc-f1" v-if="assistencia.permite_criar_visita && item.visita_liberada">
						<router-link
						:to="{
							name: 'novaVisitaTecnica',
							params: { idAssistencia: assistencia.idassistencia, item: item.id, idAssistenciaItem: item.iditem }
						}" class="pc-btnBox pc-btnBox-icon-right pc-btnBox-small">Agendar Visita</router-link>
					</div>
					<div v-else-if="assistencia.permite_criar_visita" class="pc-tabela--td pc-f1"> <p>Já existe uma visita em andamento para o item.</p></div>
				</div>
			</div>
			<br>
			<div v-if="assistencia.visitas">
				<div class="pc-titleBloco">
					<h3>Visitas</h3>
				</div>
				<div class="pc-tabela">
					<div class="pc-tabela--row pc-tabela--head">
						<div class="pc-tabela--td pc-f1">
							<p>#ID</p>
						</div>
						<div class="pc-tabela--td pc-f2">
							<p>Item <br> Descrição</p>
						</div>
						<div class="pc-tabela--td pc-f1">
							<p>Período da Visita</p>
						</div>
						<div class="pc-tabela--td pc-f2">
							<p>Observação</p>
						</div>
						<div class="pc-tabela--td pc-f1">
							<p>Situação</p>
						</div>
					</div>
					<div class="pc-tabela--row" v-for="visita in assistencia.visitas" :key="visita.id">
						<div class="pc-tabela--td pc-f1">{{ visita.id }}</div>
						<div class="pc-tabela--td pc-f2">{{ getSafe(() => visita.item.nome) }}<br>
							{{ getSafe(() => visita.item.descricao) }}
						</div>
						<div class="pc-tabela--td pc-f1">
							<span>
								{{
										visita.periodo_de
										| dateParse("YYYY-MM-DD HH:mm")
										| dateFormat("DD/MM/YYYY HH:mm")
								}}
							</span>
						</div>
						<div class="pc-tabela--td pc-f2">
							<span class="cv-descricao-mensagem" v-html="quebraDeLinhaParaHtml(visita.observacao)"></span></div>
						<div class="pc-tabela--td pc-f1">
							<span class="pc-labelStatus" :style="
								`background: ${visita.situacao.situacao_cor_bg}; color: ${visita.situacao.situacao_cor_nome};`
							">{{ visita.situacao.nome }}</span>
						</div>

					</div>
				</div>
			</div>
		</div>
		<div class="pc-bloco">
			<Mensagens v-if="assistencia.mensagens && assistencia.mensagens.length > 0"
				:mensagens="assistencia.mensagens"
			/>

			<Responder :assistencia="assistencia" @error="enviarErro" @refresh="$emit('refresh')" />
		</div>
	</div>
</template>

<script>
import getSafe from "@/plugins/OptionalChain";
import { quebraDeLinhaParaHtml } from "@/plugins/Utils";

import Responder from "./Responder";
import Mensagens from "./Mensagens";
export default {
	props: ["assistencia"],
	methods: {
		enviarErro(erro) {
			this.$emit("error", erro);
		},
		getSafe,
		quebraDeLinhaParaHtml
	},

	components: {
		Mensagens,
		Responder
	},
};
</script>
