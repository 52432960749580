<template>
    <div class="pc-modalPopostaAtiva--proposta">
        <div class="pc-ft">
            <img
                v-if="reserva.unidade.empreendimento.foto"
                :src="
                    reserva.unidade.empreendimento.foto.url
                        .replace('[[LARGURA]]', 'x')
                        .replace('[[ALTURA]]', 'x')
                "
                alt=""
            />
            <img src="@/assets/img/ft.jpg" alt="" v-else />
        </div>
        <div class="pc-info">
            <h3>{{ reserva.unidade.empreendimento.nome }}</h3>
            <p></p>
            <ul>
                <li>
                    <p>
                        Bloco Selecionado<span>{{
                            reserva.unidade.bloco.nome
                        }}</span>
                    </p>
                </li>
                <li>
                    <p>
                        Coluna Selecionada
                        <span v-if="reserva.unidade.coluna">{{
                            reserva.unidade.coluna
                        }}</span>
                        <span v-else>--</span>
                    </p>
                </li>
                <li>
                    <p>
                        Andar Selecionado
                        <span v-if="reserva.unidade.andar">{{
                            reserva.unidade.andar
                        }}</span>
                        <span v-else>--</span>
                    </p>
                </li>
                <li>
                    <p>
                        Unidade Selecionada<span>{{
                            reserva.unidade.nome
                        }}</span>
                    </p>
                </li>
            </ul>
            <div class="pc-alert pc-warning">
                <p>Nessa proposta falta(m) {{reserva.documentos_faltando}} documento(s) obrigatório(s).</p>
            </div>
            <div class="pc-footer">
                <h4>
                    <span>Valor da Unidade</span>R$ {{ reserva.valor_unidade }}
                </h4>
                <div class="pc-btns">
                    <a
                        v-if="reserva.hash_reserva && reserva.hash_cliente"
                        @click.prevent="submitProposta()"
                        href="javascript:void(0)"
                        target="_blank"
                        class="
                            pc-btnGhost pc-btnGhost-small pc-btnGhost-primary
                        "
                        :class="acessoComo ? 'disabled' : null"
                    >
                        Enviar Documentos
                    </a>
                    <router-link
                        class="
                            pc-btnGhost pc-btnGhost-small pc-btnGhost-primary
                        "
                        target="_blank"
                        :to="{ name: 'unidades-disponiveis' }"
                        >Agendar uma Visita
                    </router-link>
                </div>
            </div>
        </div>
        <form ref="form" method="POST" :action="!acessoComo ? `/plugins/minhaproposta/${reserva.hash_reserva}/${reserva.hash_cliente}` : null" target="_blank">
            <input type="hidden" name="token_cliente" :value="$store.state.login.access_token">
        </form>
    </div>
</template>

<script>
import getSafe from "@/plugins/OptionalChain";
import { mapGetters } from "vuex";

export default {
    props: {
        reserva: { type: Object, required: true },
    },

    name: "ModalInfoReservas",

    computed: {
        ...mapGetters({
            acessoComo: "getAcessoComo",
        }),
    },

    created() {
    },

    methods: { 
        getSafe,

        submitProposta() {
            this.$refs.form.submit();
        }
    },
};
</script>
