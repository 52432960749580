<template>
    <div class="row">
        <div class="col-12">
            <div class="pc-titlePage g-20">
                <div class="pc-titlePage--title">
                    <h1>{{ titulo }}</h1>
                    <p>{{ subtitulo }}</p>
                </div>
                <!-- <InputSearch v-if="mostrarFiltros" /> -->
                <div class="d-flex g-15">
                    <ButtonFilters @show-sidebar-filters="showSidebarFilters" v-if="mostrarFiltros" />
                    <HomeButton />
                    <slot></slot>
                </div>
            </div>
            <!-- <SidebarFilters ref="sidebarFilters" /> -->
        </div>
    </div>
</template>

<script>
    import InputSearch from "@/components/inputs/InputSearch";
    import ButtonFilters from "@/components/buttons/ButtonFilters";
    import HomeButton from "@/components/HomeButton";
    import SidebarFilters from "@/components/sidebars/SidebarFilters";

    export default {
        props: {
            titulo: { type: String, required: true },
            subtitulo: { type: String, required: true },
            mostrarFiltros: { type: Boolean, default: false },
        },

        components: {
            InputSearch,
            ButtonFilters,
            HomeButton,
            SidebarFilters,
        },

        methods: {
            showSidebarFilters() {
                this.$emit('showSidebarFilters', true);
            }
        }
    };
</script>

<style>
    .pc-titlePage {
        flex-wrap: wrap;
    }
    
    .g-15 {
        gap: 15px;
    }

    .g-20 {
        gap: 20px;
    }
</style>
