<template>
  <div class="tc-wrap">
    <div class="tc-logo">
      <img
        class="img-fluid"
        v-if="configuracoes.logo"
        :src="configuracoes.logo.url"
        alt=""
      />
      <img
        class="img-fluid"
        v-else
        :src="baseUrl + '/especifico/img/logo_empresa.png'"
        alt=""
      />
    </div>
    <div class="tc-title mb-20">
      <h3>Atualização Cadastral</h3>
      <p class="mt-10">
        Você deve manter seus dados atualizados.
      </p>
    </div>

    <div class="pc-bloco">
      <div class="pc-titleBloco">
        <div>
          <h3>Dados do Cliente</h3>
        </div>
      </div>
      <LoadingComponent :isLoading="isLoading" />
      <div class="row mt-10" style="text-align: start">
        <div class="col-lg-6 col-12" v-if="this.campos.includes('nome')">
          <div class="form-group">
            <label for="nome">Nome</label>
            <input
              v-model="formCliente.nome"
              type="text"
              class="form-control"
              placeholder="Nome"
              id="nome"
              name="nome"
            />
          </div>
        </div>

        <div class="col-lg-6 col-12" v-if="this.campos.includes('rg')">
          <div class="form-group">
            <label for="rg">RG</label>
            <input
              v-model="formCliente.rg"
              v-mask="'##.###.###-#'"
              type="text"
              class="form-control"
              placeholder="RG"
              id="rg"
              name="rg"
            />
          </div>
        </div>

        <div class="col-lg-6 col-12" v-if="this.campos.includes('telefone')">
          <div class="form-group">
            <label for="telefone">Telefone</label>
            <vue-tel-input
              v-model="formCliente.telefone"
              v-bind="bindProps"
              id="telefone"
              name="telefone"
            >
            </vue-tel-input>
          </div>
        </div>

        <div class="col-lg-6 col-12" v-if="this.campos.includes('celular')">
          <div class="form-group">
            <label for="celular">Celular</label>
            <vue-tel-input
              v-model="formCliente.celular"
              v-bind="bindProps"
              id="celular"
              name="celular"
            >
            </vue-tel-input>
          </div>
        </div>

        <div class="col-lg-6 col-12" v-if="this.campos.includes('email')">
          <div class="form-group">
            <label for="email">Email</label>
            <input
              v-model="formCliente.email"
              type="text"
              class="form-control"
              placeholder="Email"
              id="email"
              name="email"
            />
          </div>
        </div>

        <div class="col-lg-6 col-12" v-if="this.campos.includes('renda_familiar')">
          <div class="form-group">
            <label for="renda_familiar">Renda Familiar</label>
            <input
              v-model="formCliente.renda_familiar"
              @keypress="onlyNumber"
              maxlength="10"
              type="text"
              class="form-control"
              placeholder="Renda Familiar"
              id="renda_familiar"
              name="renda_familiar"
            />
          </div>
        </div>

        <div class="col-lg-6 col-12" v-if="this.campos.includes('cep')">
          <div class="form-group">
            <label>CEP</label>
            <input
              type="text"
              class="form-control input-tel"
              placeholder="CEP"
              v-model="formEndereco.cep"
              v-mask="'#####-###'"
              @keyup="enderecoPorCep"
              id="cep"
              name="cep"
            />
          </div>
        </div>

        <div class="col-lg-6 col-12" v-if="this.campos.includes('logradouro')">
          <div class="form-group">
            <SelectInput
              titulo="Logradouro"
              label="nome"
              :clearable="false"
              :options="logradouros"
              :reduce="(option) => option.idlogradouro"
              :valor.sync="formEndereco.logradouro.idlogradouro"
            />
          </div>
        </div>

        <div class="col-lg-6 col-12" v-if="this.campos.includes('endereco')">
          <div class="form-group">
            <label>Endereço</label>
            <input
              type="text"
              class="form-control input-alpha"
              maxlength="100"
              v-model="formEndereco.nome"
              placeholder="Endereço"
            />
          </div>
        </div>

        <div class="col-lg-6 col-12" v-if="this.campos.includes('bairro')">
          <div class="form-group">
            <label>Bairro</label>
            <input
              type="text"
              class="form-control input-alpha"
              maxlength="100"
              v-model="formEndereco.bairro"
              placeholder="Bairro"
            />
          </div>
        </div>

        <div class="col-lg-6 col-12" v-if="this.campos.includes('numero')">
          <div class="form-group">
            <label>Número</label>
            <input
              type="text"
              class="form-control input-alpha"
              maxlength="10"
              v-model="formEndereco.numero"
              placeholder="Número"
            />
          </div>
        </div>

        <div class="col-lg-6 col-12" v-if="this.campos.includes('complemento')">
          <div class="form-group">
            <label>Complemento</label>
            <input
              type="text"
              class="form-control input-alpha"
              maxlength="100"
              v-model="formEndereco.complemento"
              placeholder="Complemento"
            />
          </div>
        </div>

        <div class="col-lg-6 col-12" v-if="this.campos.includes('estado')">
          <div class="form-group">
            <SelectInput
              titulo="Estado"
              label="nome"
              regras="required"
              :clearable="false"
              :options="estados"
              :reduce="(option) => option.idestado"
              :valor.sync="formEndereco.estado.idestado"
              @update:valor="atualizarCidades(null)"
            />
          </div>
        </div>

        <div class="col-lg-6 col-12" v-if="this.campos.includes('cidade')">
          <div class="form-group">
            <SelectInput
              titulo="Cidade"
              label="nome"
              regras="required"
              :clearable="false"
              :options="cidades"
              :reduce="(option) => option.idcidade"
              :valor.sync="formEndereco.cidade.idcidade"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6 btnEnviar">
          <button
            name="btSend"
            type="button"
            value="Alterar"
            class="btn-form pc-btnBox pc-btnBox-secondary pc-btnBox--fullW"
            @click="enviarFormulario()"
          >
            Salvar modificações
          </button>
        </div>
        <div class="col-12 col-md-6 btnEnviar">
          <button
            name="btSend"
            type="button"
            value="Alterar"
            class="btn-form pc-btnBox pc-btnBox-secondary pc-btnBox--fullW"
            @click="enviarEstouAtualizado()"
          >
            Estou atualizado
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import LoadingComponent from "@/components/LoadingComponent.vue";
import SelectInput from "@/components/SelectInput.vue";
import {URL_API} from "@/bootstrap";
import {objetoTraking, removerMascaraString, removerMascaraTelefone} from "@/plugins/Utils";
import Vue from "vue";
import {VueTelInput} from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
import {getCidades, getEnderecoPorCep, getEstados, getLogradouros} from "@/services/EnderecoService";
import cloneDeep from "clone-deep";
import {postAtualizacaoCadastral, postEstouAtualizado} from "@/services/AtualizacaoCadastralService";

Vue.use(VueTelInput);

export default {
  name: "AtualizacaoCadastral",

  components: {
    SelectInput,
    LoadingComponent,
    VueTelInput,
  },

  computed: {
    ...mapGetters({
      configuracoes: "getConfiguracoes",
      campos: "retornarCamposAtualizacaoCadastral",
      cliente: "getCliente",
    }),
  },

  data() {
    return {
      isLoading: false,
      baseUrl: URL_API,
      senha_regex: null,
      formCliente: null,
      formEndereco: null,

      logradouros: [],
      estados: [],
      cidades: [],

      bindProps: {
        nationalMode: false,
        separateDialCode: true,
        formatOnDisplay: true,
        initialCountry: "BR",
        preferredCountries: ["BR"],
        autoHideDialCode: true,
        enabledCountryCode: true,
        mode: 'international',
        validCharactersOnly: true,
        inputOptions: {
          showDialCode: false
        },
      },
    };
  },

  beforeMount() {
    this.formCliente = cloneDeep(this.cliente);
    this.formEndereco = cloneDeep(this.formCliente.endereco);
    delete this.formCliente.endereco;

    getLogradouros()
      .then((response) => {
        this.logradouros = response.data;
      })
      .catch((error) => {
      });

    getEstados()
      .then((response) => {
        this.estados = response.data;
      })
      .catch((error) => {
      });

    if (this.formEndereco.estado) {
      this.atualizarCidades(this.formEndereco.cidade.idcidade);
    }
  },

  methods: {
    ...mapActions(["getMeOnlyMe", "setPendencia"]),

    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        $event.preventDefault();
      }
    },

    enderecoPorCep() {
      if (this.formEndereco.cep.length === 9) {
        this.isLoading = true;
        getEnderecoPorCep(removerMascaraString(this.formCliente.cep))
          .then((response) => {
            this.formEndereco.logradouro.idLogradouro = Number(
              response.data.idlogradouro
            );
            this.formEndereco.nome = response.data.endereco;
            this.formEndereco.bairro = response.data.bairro;
            this.formEndereco.estado.idEstado = Number(
              response.data.idestado
            );
            this.formEndereco.numero = "";
            this.formEndereco.complemento = "";
            this.atualizarCidades(Number(response.data.idcidade));
          })
          .catch((error) => {
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },

    atualizarCidades(idCidade) {
      getCidades(this.formEndereco.estado.idestado)
        .then((response) => {
          this.cidades = response.data;
          this.formEndereco.cidade.idcidade = idCidade;
        })
        .catch((error) => {
        });
    },

    trakingMixpanel(acao, subacao, intencao, ateracao) {
      this.$mixpanel.track(
        Vue.prototype.$eventoMixPanel,
        objetoTraking("Atualização Cadastral", acao, subacao, intencao, ateracao)
      );
    },

    enderecoToForm() {
      if (this.formEndereco.cep) {
        this.formCliente.cep = this.formEndereco.cep;
      }
      if (this.formEndereco.logradouro) {
        this.formCliente.idlogradouro = this.formEndereco.logradouro.idlogradouro;
      }
      if (this.formEndereco.bairro) {
        this.formCliente.bairro = this.formEndereco.bairro;
      }
      if (this.formEndereco.numero) {
        this.formCliente.numero = this.formEndereco.numero;
      }
      if (this.formEndereco.complemento) {
        this.formCliente.complemento = this.formEndereco.complemento;
      }
      if (this.formEndereco.estado) {
        this.formCliente.idestado = this.formEndereco.estado.idestado;
      }
      if (this.formEndereco.cidade) {
        this.formCliente.idcidade = this.formEndereco.cidade.idcidade;
      }
      if (this.formEndereco.nome) {
        this.formCliente.endereco = this.formEndereco.nome;
      }
    },

    enviarFormulario() {
      this.isLoading = true;
      if (this.formCliente.rg) {
        this.formCliente.rg = removerMascaraString(this.formCliente.rg);
      }
      if (this.formCliente.telefone) {
        this.formCliente.telefone = removerMascaraTelefone(
          this.formCliente.telefone
        );
      }
      if (this.formCliente.celular) {
        this.formCliente.celular = removerMascaraTelefone(
          this.formCliente.celular
        );
      }
      if (this.formEndereco.cep) {
        this.formEndereco.cep = removerMascaraString(
          this.formEndereco.cep
        );
      }
      this.enderecoToForm();

      postAtualizacaoCadastral(this.formCliente)
        .then((response) => {
          this.getMeOnlyMe();
          this.trakingMixpanel(
            "Atualização cadastral - Alterar dados do cliente",
            "Alterar",
            true,
            true
          );
          this.setPendencia(false);
          this.$router.push({name: "home"});
        })
        .catch((error) => {
          this.$router.push({name: "home"});
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    enviarEstouAtualizado() {
      this.isLoading = true;

      postEstouAtualizado()
        .then(() => {
          this.trakingMixpanel(
            "Atualização cadastral - Manter dados do cliente",
            "Manter",
            true,
            false
          );
          this.setPendencia(false);
          this.$router.push({name: "home"});
        })
        .catch((error) => {
          this.$router.push({name: "home"});
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
